<template>
  <WidgetFrame @onResize="onResize" @onOrientationChange="onOrientationChange">
    <template v-slot:title>
      Departments
    </template>
    <template v-slot:content>
      <div v-if="!canView('PROJECT', ['TASK'])" class="center-text">
        {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
      </div>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else-if="noTasks">
        <div class='status-message'>No tasks in project.</div>
      </template>
      <template v-else>
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text" for="skillsType">
              {{$t('dashboard.widgets.view_by')}}
            </label>
          </div>
          <b-form-select id="skillsType" 
            :data-vv-as="$t('dashboard.widgets.view_by')"
            data-vv-name="dashboard.widgets.view_by"
            data-vv-delay="500"
            :options="optionTypes"
            text-field="label"
            value-field="key"
            v-model="type"
            />
        </div>
        <div class='chart-holder'>
          <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>          
        </div>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
  import {AgChartsVue} from 'ag-charts-vue';
  import WidgetFrame from "@/components/Dashboard/WidgetFrame";
  import settings from "@/_dashboardSettings";
  import { EventBus } from '@/helpers';
  import { projectService } from '@/services';
  import { tooltipRendererGenerator } from '@/helpers/ag-chart-tooltip-renderer';

  const maybePluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

  export default {
    name: 'ProjectDepartmentsWidget',
    components: {
      AgChartsVue,
      WidgetFrame
    },
    props: {
      profile:    { type: Object, required: true },
      project:    { type: Object, required: true }
    },
    watch: {
      type(newValue, oldValue) {
        if (oldValue != null) {
          this.saveSettings();
          this.buildData();
        }
      }
    },
    data() {
      return {
        orientation: null,
        width: 0,
        height: 0,
        loaded: false,
        noTasks: false,
        options: null,
        sourceData: [],
        title: "",
        type: null,
        optionTypes: [
          {key: 'cost', label: 'Estimated Cost'},
          {key: 'duration', label: 'Estimated Duration'},
          {key: 'headCount', label: 'Head Count'},
          {key: 'headCountBooking', label: 'Booking Head Count'}
        ]
      };
    },
    created() {
      EventBus.$on('theme-change', () => {
        if (this.loaded) {
          this.buildChart();
        }
      });

      // Use 'number' default type if profile doesn't have a valid key
      const pType = this.profile.optionType;
      this.type = !(this.optionTypes.map(t => t.key).includes(pType)) ? 'cost' : pType;

      this.buildData();
    },
    beforeDestroy() {
      EventBus.$off('theme-change');
    },
    methods: {
      async buildData() {
        const self = this;
        this.sourceData = [];
        const deptData = await projectService.dashboardListDepartments(this.project.uuId, this.type === 'headCountBooking').then(response => {
          return response.data;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });

        let deptMap = {};
        for (const d of deptData) {
          if (d.name in deptMap) {
            deptMap[d.name].cost += d.estimatedCost;
            deptMap[d.name].duration += d.estimatedDuration;
            deptMap[d.name].headCount += d.headCount;
            deptMap[d.name].headCountBooking += d.headCountBooking;
          }
          else {
            deptMap[d.name] = {};
            deptMap[d.name].cost = d.estimatedCost;
            deptMap[d.name].duration = d.estimatedDuration;
            deptMap[d.name].headCount = d.headCount;
            deptMap[d.name].headCountBooking = d.headCountBooking;
          }
        }
        this.title = "Departments";
        const data = [];
        for (const key of Object.keys(deptMap)) {
          data.push({ name: key, cost: deptMap[key].cost, duration: deptMap[key].duration, headCount: deptMap[key].headCount, headCountBooking: deptMap[key].headCountBooking });
        }
        
        if (data.length === 0) {
          this.noTasks = true;
        }
        else {
          this.noTasks = false;
        }
        
        if (this.type == "cost") {
          self.sourceData = data.map(s => { return { name: s.name, count: s.cost }});
        } else if (this.type == "duration") {
          self.sourceData = data.map(s => { return { name: s.name, count: s.duration }});
        } else if (this.type == "headCount") {
          self.sourceData = data.map(s => { return { name: s.name, count: s.headCount }});
        } else if (this.type == "headCountBooking") {
          self.sourceData = data.map(s => { return { name: s.name, count: s.headCountBooking }});
        } else {
          console.error("Unknown skills list type"); // eslint-disable-line no-console
        }

        
        this.buildChart();
        this.loaded = true;
      },
      buildChart() {
        this.options = settings.getColumnChartOptions(this.width);
        this.options.title.text = this.title;
        this.options.series[0].data = this.sourceData;
        this.options.series[0].xKey = 'name';
        this.options.series[0].yKeys = ['count'];
        this.options.series[0].yNames = [this.$t('staff.title')];

        let contentLabel = this.type == "headCount" || this.type === "headCountBooking" ? this.$t('staff.title') : this.$t('label.task_plural');
        let contentValueFormatter = null;
        if (this.type == "cost") {
          contentLabel = this.$t('task.field.estimatedCost');
          contentValueFormatter = (value) => (value == null || isNaN(value))? '$0' : `$${parseFloat(value).toFixed(0).toString()}`;
          this.options.series[0].yNames = [this.$t('task.field.estimatedCost')];
        }
        else if (this.type == "duration") {
          contentLabel = this.$t('task.field.estimatedDuration');
          contentValueFormatter = (value) => (value == null || isNaN(value))? '0D' : `${parseFloat(value).toFixed(0).toString()}D`;
          this.options.series[0].yNames = [this.$t('task.field.estimatedDuration')];
        }
        this.options.series[0].tooltip = {
          enabled: true,
          renderer: tooltipRendererGenerator({ title: 'datum["name"]', staticContentLabel: contentLabel, contentValue: 'datum.count', contentValueFormatter })
        }
      },
      saveSettings() {
        this.profile.optionType = this.type;
        this.$emit('saveWidget', this.profile);
      },
      onOrientationChange({orientation, width, height}) {
        // console.log(this.$options.name + ": Orientation change");
        this.orientation = orientation;
        this.onResize({width, height});
      },
      onResize({width, height}) {
        // console.log(this.$options.name + ": W: " + width + " H: " + height);
        this.width = width;
        this.height = height;
        if (this.loaded) {
          this.buildChart();
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .center-text {
    text-align: center;
    margin: auto;
  }
</style>